import React from 'react'

import './TeamImg.css'

function TeamImg(props) {
  return (
    <div className='circle'>
        <img className="img-src" src={props.src} alt={props.alt}/>
    </div>
        
  )
}

export default TeamImg