import "./App.css";

import Header from "./components/Header";
import Footer from "./components/Footer";
import TeamImg from "./components/TeamImg";
import ProcessImg from "./components/ProcessImg";
import MobileHeader from "./components/MobileHeader";
import Projects from "./components/Projects";

import logo from "./assets/images/right-facing-logo.svg";

const mattImg = require("./assets/images/matt-profile.png");
const designImg = require("./assets/images/design.jpg");
const developImg = require("./assets/images/develop.jpg");
const deployImg = require("./assets/images/deploy.jpg");

function App() {

    const openInNewTab = (url) => {
        window.open(url, "_blank", "noopener,noreferrer");
    };

    return (
        <div className="app">
            {window.innerWidth > 1000 ? <Header /> : <MobileHeader />}
            <div className="content">
                <div className="logo-section">
                    <h1 className="logo-text">Cool Panda Software</h1>
                    <img src={logo} alt="logo" className="main-logo" />
                </div>

                <div className="about-section" id="about">
                    <h1 className="section-title">About</h1>
                    <p className="section-text">
                        Cool Panda Software is a team of dedicated software
                        engineers who aim to provide excellent service to our
                        clients. Our developers have industry experience in big
                        tech at companies such as Microsoft, and research
                        experience at accredited institutions like Michigan
                        State University. We have years of experience working
                        with big data, AI, web development, mobile app
                        development, etc. Check out some of our favorite
                        projects at the bottom.
                    </p>
                </div>

                <div className="process-section" id="process">
                    <h1 className="section-title">Process</h1>
                    <div className="process-step-container">
                        <ProcessImg src={designImg} alt={"design"} />
                        <div className="process-step-text-container">
                            <h1 className="process-step-title">Design</h1>
                            <p className="section-text">
                                We work very closely with our clients to truly
                                understand what they want together. We then
                                meticulously design and present our solutions to
                                make sure they meet your needs exactly how you
                                want to.
                            </p>
                        </div>
                    </div>

                    <div className="process-step-container">
                        <div className="process-step-text-container">
                            <h1 className="process-step-title">Develop</h1>
                            <p className="section-text">
                                With a strong design in place, our developers
                                get to work creating it immediately. We work
                                very closely with our clients by presenting them
                                with prototypes and updates along the way to
                                make sure they are satisfied with their
                                technology.
                            </p>
                        </div>
                        <ProcessImg src={developImg} alt={"develop"} />
                    </div>

                    <div className="process-step-container">
                        <ProcessImg src={deployImg} alt={"deploy"} />
                        <div className="process-step-text-container">
                            <h1 className="process-step-title">Deploy</h1>
                            <p className="section-text">
                                No creation is complete without being deployed.
                                We will make sure the systems we create are up
                                and running, and also ensure that they're
                                healthy. We always provide our clients with
                                ample documentation and explanation so you can
                                use the systems with ease.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="team-section" id="team">
                    <h1 className="section-title">Team</h1>
                    <div className="teammate-container">
                        <div className="teammate-img">
                            <TeamImg
                                src={mattImg}
                                alt={"Matt Anikiej: Founder/ Developer"}
                            />
                        </div>
                        <div className="teammate-info">
                            <h1 className="teammate-title">Matt Anikiej</h1>
                            <h1 className="teammate-subtitle">
                                Founder/ Developer
                            </h1>
                            <p className="section-text">
                                I'm a software engineer from Chicago that is
                                driven by my passion for tech to constantly
                                learn new technologies and develop better
                                software.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="contact-section" id="contact">
                    <h1 className="section-title">Contact</h1>
                    <div className="contact-text-container">
                        <p className="contact-text">
                            coolpandasoftware@gmail.com
                        </p>
                    </div>
                </div>

                <div className="projects-section" id="projects">
                <h1 className="section-title">Projects</h1>
                    <div className="projects-wrapper">
                        <Projects title="Cool Panda VST/AU Plugins" onClick={() => openInNewTab("https://coolxpandaxplugins.com")}/>
                        <Projects title="MSU Club Lacrosse Website" onClick={() => openInNewTab("https://msuclublacrosse.com")}/>
                        <Projects title="Obi-Wan Kenobi AI Discord Bot" onClick={() => openInNewTab("https://discord.com/api/oauth2/authorize?client_id=956584753874743368&permissions=414464609344&scope=bot")}/>
                        <Projects title="MSU Judo Club Website" onClick={() => openInNewTab("https://msujudoclub.com")}/>
                        <Projects title="Cool Panda Pedals Website" onClick={() => openInNewTab("https://coolxpandaxpedals.com")}/>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default App;
