import React from "react";
import { Link } from "react-scroll";

import "./MobileHeader.css";

import logo from "../assets/images/right-facing-logo.svg";

function MobileHeader() {
    return (
        <div className="mobile-header-container">
            <Link to="app" smooth={true} offset={-15} className="header-logo">
                <img src={logo} alt="logo" className="mobile-header-logo" />
            </Link>
        </div>
    );
}

export default MobileHeader;
