import React from "react";
import {Link} from 'react-scroll'

import "./Header.css";

function Header() {

    return (
        <div className="header-container">
            <div className="logo-container">
            <Link to="app" smooth={true} offset={-15} className="header-logo">Cool Panda</Link>
            </div>
            <div className="nav-container">
                <Link to="about" smooth={true} offset={-15} className="nav-item">About</Link>
                <Link to="process" smooth={true} offset={-15} className="nav-item">Process</Link>
                <Link to="team" smooth={true} offset={-15} className="nav-item">Team</Link>
                <Link to="contact" smooth={true} offset={-15} className="nav-item">Contact</Link>
                <Link to="projects" smooth={true} offset={-15} className="nav-item">Projects</Link>
            </div>
        </div>
    );
}

export default Header;
