import React from 'react'

import './ProcessImg.css'

function ProcessImg(props) {
  return (
    <img className="process-img-src" src={props.src} alt={props.alt}/>
  )
}

export default ProcessImg