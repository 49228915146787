import React from 'react'

import './Projects.css';

function Projects(props) {
  return (
    <button className='project-button' onClick={props.onClick}>{props.title}</button>
  )
}

export default Projects